import React from 'react'
import { Check, TriangleAlert, X } from 'lucide-react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { updateFirstTime } from '../../../../config/store/reducers/authReducer'

const Modal = ({
  title,
  desc,
  imageicon,
  cardmultiplebtn,
  type,
  link,
  isOpen,
  onClose,
  welcomeicon,
  linkOverride
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Icon logic based on success or failure type
  const icon =
    type === 'success' ? (
      <Check className='text-darkGreen w-[80px] h-[80px]' size={40} />
    ) : (
      <TriangleAlert className='text-red-400 w-[80px] h-[80px]' size={40} />
    )

  // Border and background image class based on the modal type
  const noborder =
    type === 'success'
      ? 'border-solid border-darkgreen border-2'
      : 'border-none'
  const backgroundimage = welcomeicon
    ? 'bg-modalimg'
    : type === 'success'
    ? 'bg-modalimg'
    : 'bg-errorbg'

  // Button titles
  const btntitle = type === 'success' ? 'Continue' : 'Try Again'

  // Function to handle redirection when the button is clicked
  const handleRedirect = () => {
    if (type === 'success' && link) {
      if (link === '/profile') {
        // Reload the current page if the link is "/profile"
        window.location.reload()
      } else if (link) {
        navigate(link) // Navigate to the link if it's not "/profile"
      }
    } else {
      onClose() // Close the modal if it's not a success type
    }
    dispatch(updateFirstTime()) // Update first time regardless of type
  }

  // Close icon handler based on linkOverride
  const handleClose = () => {
    if (title === 'Link withdrawal bank') {
      onClose()
    } else if (type === 'success' && link) {
      if (link === '/profile') {
        window.location.reload()
      }
    } else if (linkOverride) {
      navigate('/dashboard')
    } else {
      onClose()
    }
  }

  if (!isOpen) return null

  return (
    <div className='fixed inset-0 bg-black top-[-20px] bg-opacity-50 flex items-center justify-center z-50 '>
      <div className='bg-white lg:w-[30%] h-[400px] w-[90%] rounded-lg'>
        <div className='dialog'>
          {/* Modal Top Section */}
          <div
            className={`w-full h-[50%] ${backgroundimage} p-4 bg-cover bg-top relative flex justify-center items-center`}
          >
            {(!welcomeicon || linkOverride) && (
              <button className='b-none absolute top-4 right-4'>
                <X
                  size={20}
                  className='text-red-600 w-6 h-6 rounded-full bg-white'
                  onClick={handleClose}
                />
              </button>
            )}

            <div className='w-[50%] h-full flex justify-center items-center'>
              {welcomeicon ? (
                <div className='w-[40%] flex justify-center items-center'>
                  <img src={welcomeicon} alt='welcome icon' />
                </div>
              ) : imageicon ? (
                <div className='w-[30%] flex justify-center items-center'>
                  <img src={imageicon} alt='icon' />
                </div>
              ) : (
                <div
                  className={`${noborder} rounded-full p-2 w-[50%] flex justify-center items-center`}
                >
                  {icon}
                </div>
              )}
            </div>
          </div>

          {/* Modal Bottom Section */}
          <div className='w-full h-[50%] center flex-col p-5 text-center text-black '>
            <h1 className='font-semibold'>{title}</h1>
            <p className='ts'>{desc}</p>
            {welcomeicon ? (
              <div className='w-full center gap-2 ts text-white mt-4'>
                {!linkOverride && (
                  <button
                    onClick={handleRedirect} // Redirect on click
                    className='w-[48%] h-[50px] bg-[#DDDDDD] text-black rounded-md ts'
                  >
                    Skip for now
                  </button>
                )}
                <Link
                  to={
                    title === 'Link withdrawal bank'
                      ? '/profile?bank=true'
                      : linkOverride
                      ? '/businesses/new-business'
                      : '/operational-calendar/create-operational-calendar'
                  }
                  className={`h-[50px] bg-darkGreen text-white rounded-md ts text-center flex justify-center items-center ${
                    linkOverride || title === 'Link withdrawal bank'
                      ? 'w-full'
                      : 'w-[48%]'
                  }`}
                >
                  {title === 'Link withdrawal bank'
                    ? 'Link bank'
                    : linkOverride
                    ? 'Create Business'
                    : 'Create Calendar'}
                </Link>
              </div>
            ) : cardmultiplebtn ? (
              <div className='w-full center gap-2 ts text-white mt-4'>
                <button className='w-[50%] h-[50px] bg-[#D92534] rounded-md ts'>
                  No, Cancel
                </button>
                <button className='w-[50%] h-[50px] bg-[#5CB85C] rounded-md ts'>
                  Yes, Change
                </button>
              </div>
            ) : (
              <button
                className='rounded-lg h-[50px] bg-darkGreen text-white w-[70%] mx-auto ts'
                onClick={handleRedirect}
              >
                {btntitle}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
