import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import TextInput from '../../SharedComponents/TextInput/TextInput'
import { ChevronDown } from 'lucide-react'
import EnterpriseBusinessTypeSelector from './EnterpriseBusinessTypeSelector'
import EnterpriseSignUpConfirmation from './EnterpriseSignUpConfirmation'
import useBackendService from '../../../../config/services/backend-service'
import { useAuth } from '../../../../config/contexts/authProvider'
import { toast } from 'react-toastify'

const EnterpriseCeoRegistration = () => {
  const { farm } = useAuth()
  const [selectedValue, setSelectedValue] = useState('')
  const [success, setSuccess] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      businesstype: []
    }
  })

  const handleSave = () => {
    if (selectedValue) {
      const selectedSubtypes = Object.keys(selectedValue)
      setValue('businesstype', selectedSubtypes, { shouldValidate: true })
    }
  }

  const { mutate } = useBackendService('/auth/farm', 'post', {
    onSuccess: data => {
      setSuccess(true)
    },
    onError: error => {
      if (error?.response?.data?.message === 'Email already exists') {
        toast.error('This email is already registered. Please use another one.')
      } else {
        toast.error('Something went wrong. Please try again.')
      }
    }
  })

  const onSubmit = async data => {
    const payload = {
      email: farm.email,
      phoneNumber: farm.phoneNumber,
      isParentFarm: farm.isParentFarm,
      name: farm.farmname,
      state: farm.location,
      additionalData: {
        staffs: farm.staffs,
        branches: farm.branches,
        type: data.businesstype,
        ceo_name: data.ceoname,
        ceo_email: data.ceoemail,
        cac_number: data.cacnumber
      }
    }
    mutate(payload)
  }
  // Track the values of the required fields
  const ceoName = watch('ceoname')
  const ceoEmail = watch('ceoemail')
  const cacNumber = watch('cacnumber')

  const isFormFilled = ceoName && ceoEmail && cacNumber && selectedValue

  return (
    <div className='content-cover'>
      {/* Conditionally render success content */}
      {success ? (
        <EnterpriseSignUpConfirmation />
      ) : (
        // Show form if success is false
        <>
          <div className='w-full space-y-1'>
            <h1 className='header-text'>Business Details</h1>
            <p className='text-black ts'>
              Kindly fill in your details to set up your account
            </p>
          </div>

          <form className='form-wrapper' onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              name='ceoname'
              register={register}
              errors={errors}
              text='CEO name'
              placeholder='CEO name'
              validation={{ required: 'CEO name is required' }}
            />

            <TextInput
              name='ceoemail'
              register={register}
              errors={errors}
              text='CEO email'
              placeholder='CEO email'
              type='email'
              validation={{ required: 'CEO email is required' }}
            />

            <TextInput
              name='cacnumber'
              register={register}
              errors={errors}
              text='CAC number'
              placeholder='Input CAC number'
              validation={{ required: 'CAC number is required' }}
            />

            <div
              className='w-full' onClick={() =>
                document.getElementById('business').showModal()}
            >
              <TextInput
                name='businesstype'
                register={register}
                errors={errors}
                text='Type of Business'
                dvalue={selectedValue || 'Select business type'}
                placeholder='Select business type'
                calendaricon={
                  <ChevronDown
                    size={20}
                  />
              }
                validation={{ required: 'Business type is required' }}
              />
            </div>

            <button
              type='submit'
              className={`ts ${isFormFilled ? 'activebutton' : 'notactive'}`}
              disabled={!isFormFilled}
            >
              Submit
            </button>

            <EnterpriseBusinessTypeSelector
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              save={handleSave}
            />
          </form>

          <p className='login-text'>
            Have an account?{' '}
            <a href='/login' className='login-link'>
              Log in
            </a>
          </p>
        </>
      )}
    </div>
  )
}

export default EnterpriseCeoRegistration
