import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Logo from '../../../resources/logo-white.png'
import DashboardIcon from '../../../resources/whitedashboard.png'
import Manage from '../../../resources/management.png'
import ManageGreen from '../../../resources/managegreen.png'
import Products from '../../../resources/product.png'
import Sidebar from '../SideBar/Sidebar'
import TopNav from '../NavBar/TopNav'
import GreenHomeIcon from '../../../resources/home.png'
import GreenProductIcon from '../../../resources/greenproducticon.png'
import { LogOut, X } from 'lucide-react'
import { useDispatch } from 'react-redux'
import useBackendService from '../../../../config/services/backend-service'
import { logout } from '../../../../config/store/reducers/authReducer'
import { useAuth } from '../.././../../config/contexts/authProvider'
import MobileSidebar from '../MobileSideBar/MobileSideBar'
import './index.css'

const LayoutWrapper = () => {
  const [visible, setVisible] = useState(false)
  const [isManagementOpen, setIsManagementOpen] = useState(false)
  const [isMarketOpen, setIsMarketOpen] = useState(false)
  const { user } = useAuth()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { mutate } = useBackendService('/auth/logout', 'post', {
    onSuccess: () => {
      dispatch(logout())
      navigate('/')
    },
    onError: error => {
      console.error('Logout failed:', error)
    }
  })

  const handleLogout = () => {
    mutate()
  }

  const handleClick = () => {
    setVisible(true)
  }

  const menus = [
    ...(user.role === 'administrator'
      ? [
          {
            name: 'Dashboard',
            icon: DashboardIcon,
            orange: GreenHomeIcon,
            link: '/dashboard'
          }
        ]
      : []),
    {
      name: 'Calendar',
      icon: require('../../../resources/calendar.png'),
      orange: require('../../../resources/calender.png'),
      link: '/operational-calendar'
    },
    {
      name: 'Management',
      icon: Manage,
      orange: ManageGreen,
      submenu: [
        {
          name: 'Businesses',
          link: '/businesses'
        },
        {
          name: 'Products',
          link: '/farm-products'
        },

        {
          name: 'Stock',
          link: '/stocks'
        },
        ...(user.role === 'administrator'
          ? [
              {
                name: 'Staffs',
                link: '/staffs'
              }
            ]
          : []),
        {
          name: 'Operations',
          link: '/operations'
        }
      ]
    },

    {
      name: 'Market',
      icon: Products,
      orange: GreenProductIcon,
      submenu: [
        {
          name: 'Catalog',
          link: '/market/catalog'
        },
        {
          name: 'Invest',
          link: '/market/invest'
        },
        {
          name: 'Agric-news',
          link: '/market/agrinews'
        },
        {
          name: 'Sales',
          link: '/market/sales'
        },
        {
          name: 'Map',
          link: '/market/map'
        }
      ]
    },
    {
      name: 'Profile',
      icon: require('../../../resources/profile.png'),
      orange: require('../../../resources/greenprofile.png'),
      link: '/profile'
    }
  ]

  useEffect(() => {
    const managementLinks = [
      '/businesses',
      '/farm-products',
      '/stocks',
      '/staffs',
      '/operations'
    ]
    const marketLinks = [
      '/market/catalog',
      '/market/invest',
      '/market/agrinews',
      '/market/sales',
      '/market/map'
    ]

    if (managementLinks.includes(location.pathname)) {
      setIsManagementOpen(true)
    }

    if (marketLinks.includes(location.pathname)) {
      setIsMarketOpen(true)
    }
  }, [location.pathname])

  const toggleManagement = () => {
    setIsManagementOpen(prevState => !prevState)
    setIsMarketOpen(false)
  }

  const toggleMarket = () => {
    setIsMarketOpen(prevState => !prevState)
    setIsManagementOpen(false)
  }

  return (
    <div className='w-full flex max'>
      {visible && (
        <div className='fixed h-full flex justify-end w-screen lg:hidden z-10 bg-black/30 left-0 top-0'>
          <section className='bg-white w-[280px] flex flex-col items-start h-screen py-8 gap-8 z-10'>
            <div className='w-full flex px-[20px] flex-row justify-between items-center'>
              <div className='' onClick={() => setVisible(false)}>
                <img
                  src={require('../../../resources/logoleaf.png')}
                  alt=''
                  className='w-[98px]'
                />
              </div>
              <div className='' onClick={() => setVisible(false)}>
                <X size={20} />
              </div>
            </div>
            <div className='w-full gap-5 flex flex-col'>
              {menus.map((menu, index) => (
                <div key={menu.link}>
                  <div
                    className='flex justify-between items-center cursor-pointer'
                    onClick={
                      menu.name === 'Management'
                        ? toggleManagement
                        : menu.name === 'Market'
                        ? toggleMarket
                        : undefined
                    }
                  >
                    <MobileSidebar
                      name={menu.name}
                      icon={menu.icon}
                      link={menu.link}
                      orange={menu.orange}
                    />
                  </div>

                  {menu.submenu && (
                    <div
                      className={`submenu transition-all ease-in-out duration-300 overflow-hidden ${
                        (
                          menu.name === 'Management'
                            ? isManagementOpen
                            : isMarketOpen
                        )
                          ? 'max-h-screen opacity-100'
                          : 'max-h-0 opacity-0'
                      }`}
                    >
                      {menu.submenu.map((subMenu, idx) => (
                        <MobileSidebar
                          key={subMenu.link}
                          name={subMenu.name}
                          link={subMenu.link}
                        />
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className='flex-[1] justify-end flex flex-col p-3 items-end'>
              <div
                className='text-red-400 gap-2 flex ml-3'
                onClick={handleLogout}
              >
                <LogOut color='red' size={20} />
                Logout
              </div>
            </div>
          </section>
        </div>
      )}
      <div className='sidebar-wrapper bg-darkGreen'>
        <div className='top-content-wrapper'>
          <div className='logo-cover pl-[50px]'>
            <img alt='logo' src={Logo} className='w-[80px] lg:w-[142px]' />
          </div>
          <div className='mt-[20%] w-full'>
            {menus.map(menu => (
              <div key={menu.link}>
                <div
                  className='flex justify-between items-center cursor-pointer'
                  onClick={
                    menu.name === 'Management'
                      ? toggleManagement
                      : menu.name === 'Market'
                      ? toggleMarket
                      : undefined
                  }
                >
                  <Sidebar
                    name={menu.name}
                    icon={menu.icon}
                    link={menu.link}
                    orange={menu.orange}
                    isManagementOpen={isManagementOpen}
                    isMarketOpen={isMarketOpen}
                  />
                </div>

                {menu.submenu && (
                  <div
                    className={`submenu transition-all ease-in-out duration-300 overflow-hidden ${
                      (
                        menu.name === 'Management'
                          ? isManagementOpen
                          : isMarketOpen
                      )
                        ? 'max-h-screen opacity-100'
                        : 'max-h-0 opacity-0'
                    }`}
                  >
                    {menu.submenu.map(subMenu => (
                      <Sidebar
                        key={subMenu.name}
                        name={subMenu.name}
                        link={subMenu.link}
                        isSubMenu
                      />
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <button className='logout-button-style ts' onClick={handleLogout}>
          <LogOut />
          Logout
        </button>
      </div>

      <div className='topbar-wrapper'>
        <TopNav onClick={handleClick} location={useLocation()} menus={menus} />
        <div className='w-full'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default LayoutWrapper
