import React, { useState, useEffect } from 'react'
import AddNewButton from '../../SharedComponents/AddNewButton/AddNewButton'
import { useNavigate } from 'react-router-dom'
import OperationsDataTable from './DataTable/OperationsDataTable'
import useBackendService from '../../../../config/services/backend-service'
import { useAuth } from '../../../../config/contexts/authProvider'
import Lock from '../../../resources/greenlock.png'
import Modal from '../../SharedComponents/Modal/Modal'
function OperationsPage () {
  const navigate = useNavigate()
  const [incoming, setIncoming] = useState(true)
  const [data, setData] = useState([])
  const [isModalOpened, setModalOpened] = useState(false)
  const { user } = useAuth()

  const { mutate } = useBackendService('/operations', 'get', {
    onSuccess: response => {
      setData(response.data)
    },
    onError: error => {
      console.log(error)
    }
  })

  useEffect(() => {
    if (!user.farmId) {
      setModalOpened(true)
    } else {
      const filter = incoming
        ? { assigned_to: user.id }
        : { created_by: user.id }
      mutate(filter)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, incoming])
  const handleOperations = () => {
    setIncoming(prev => !prev)
  }

  const handleAddNewRequest = () => {
    navigate('/operations/add-request')
  }

  const handleAddNewReport = () => {
    navigate('/operations/add-report')
  }

  const onClick = data => {
    console.log(data)
  }

  return (
    <main className='bg-white'>
      <div className='flex flex-row items-center p-4 lg:px-10 lg:py-[30px] justify-between'>
        <p className='text-black hidden lg:flex font-[600] text-[16px] lg:text-[20px]'>
          Operations
        </p>
        <div className='flex flex-1 lg:flex-row flex-col lg:justify-end gap-2'>
          <AddNewButton title='Make a request' onClick={handleAddNewRequest} />
          <AddNewButton
            title='Add report'
            onClick={handleAddNewReport}
            color={false}
          />
        </div>
      </div>
      <div className='flex flex-row lg:gap-5 px-4 lg:px-0 lg:ml-[40px]'>
        <div
          className={`${
            incoming ? 'bg-darkGreen rounded-lg gap-1' : 'bg-white'
          } cursor-pointer flex lg:max-w-fit flex-[0.5] lg:flex-1 w-full items-center p-[10px]`}
          onClick={handleOperations}
        >
          <p
            className={`${
              incoming ? 'text-white' : 'text-black'
            } text-[12px] lg:text-[14px]`}
          >
            Incoming operation
          </p>
          <div className='size-4 rounded-full flex items-center justify-center bg-white'>
            {incoming && (
              <p className='text-darkGreen font-[600] text-[10px] lg:text-[12px]'>
                {data.length}
              </p>
            )}
          </div>
        </div>
        <div
          className={`${
            !incoming ? 'bg-darkGreen rounded-lg gap-1' : 'bg-white'
          } cursor-pointer flex lg:max-w-fit flex-[0.5] lg:flex-1 w-full items-center p-[10px]`}
          onClick={handleOperations}
        >
          <p
            className={`${
              !incoming ? 'text-white' : 'text-black'
            } text-[12px] lg:text-[14px]`}
          >
            Outgoing operations
          </p>
          <div className='size-4 rounded-full flex items-center justify-center bg-white'>
            {!incoming && (
              <p className='text-darkGreen font-[600] text-[10px] lg:text-[12px]'>
                {data.length}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className='p-4 lg:px-10  '>
        <OperationsDataTable
          data={data}
          onClick={onClick}
          title={incoming ? 'Incoming Operations' : 'Outgoing operations'}
          nav='operations'
        />
      </div>
      {/* modal for no business */}
      <Modal
        title='Create a business to unlock management tools'
        desc='Set up your agri-business to access all the tools you need to manage, track and grow with ease.'
        welcomeicon={Lock}
        isOpen={isModalOpened}
        linkOverride='true'
        onClose={() => setModalOpened(false)}
      />
    </main>
  )
}

export default OperationsPage
