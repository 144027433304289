import React from 'react'

const TextInput = ({
  name,
  register,
  errors,
  text,
  subtext,
  value,
  disabled,
  placeholder,
  edit,
  calendaricon,
  dvalue,
  type = 'text'
}) => {
  const formatValue = dvalue => {
    if (typeof dvalue === 'object') {
      return Object.keys(dvalue)
        .filter(key => dvalue[key])
        .join(', ')
    }
    return dvalue
  }

  return (
    <div className='grid w-full items-center gap-1.5'>
      <label htmlFor='email' className='text-black font-[400] ts'>
        {text}
      </label>
      {subtext && (
        <p className='text-[#888888]  font-[400] ts'>
          {subtext}
        </p>
      )}
      <div className='w-full relative'>
        <input
          type={type}
          name={name || text}
          {...register(`${name || text}`, {
            required: `${text} is required`
          })}
          placeholder={placeholder || text}
          className='email-input bg-gray-100 ts'
          defaultValue={value || ''}
          value={formatValue(dvalue)}
          disabled={disabled}
        />
        {calendaricon && (
          <button
            type='button'
            className='absolute right-3 top-3 text-gray-500 hover:text-gray-700'
          >
            {calendaricon}
          </button>
        )}
        {edit && (
          <h1 className='text-darkGreen absolute ts right-3 top-4 bottom-2 font-[500]'>
            EDIT
          </h1>
        )}
      </div>
      {errors[name] && (
        <p className='error-text text-red-500 ts'>{errors[name]?.message}</p>
      )}
    </div>
  )
}

export default TextInput
