import React, { useEffect } from 'react'
import SelectInput from '../../SharedComponents/SelectInput/SelectInput'
import { Controller, useForm } from 'react-hook-form'
import EnterpriseFarmRegistration from './EnterpriseFarmRegistration'
import TextInput from '../../SharedComponents/TextInput/TextInput'

const EnterpriseBusinessDetails = ({ next, onAnswerSelect }) => {
  const question = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' }
  ]
  const businessType = [
    { value: 'Foresty', label: 'Foresty' },
    { value: 'Tomatoes', label: 'Tomatoes' }
  ]
  const location = [
    { value: 'Lagos', label: 'Lagos' },
    { value: 'Benue', label: 'Benue' }
  ]

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isDirty, isValid }
  } = useForm()
  // Watch the 'question' field to determine if "Yes" or "No" is selected
  const selectedAnswer = watch('question')
  // Call onAnswerSelect whenever selectedAnswer changes
  useEffect(() => {
    if (onAnswerSelect) {
      console.log('Selected answer:', selectedAnswer)
      onAnswerSelect(selectedAnswer)
    }
  }, [selectedAnswer, onAnswerSelect])
  const onSubmit = async (data) => {
    console.log(data)
    const selection = selectedAnswer === 'Yes' ? 'Yes' : 'No'
    let filteredData

    if (selectedAnswer === 'Yes') {
    // Filter data for "Yes" selection
      const { question, ...yesFields } = data
      filteredData = { question, ...yesFields }
    } else if (selectedAnswer === 'No') {
    // Filter data for "No" selection
      const { question, businesstype, venture, locationchoice, budget } = data
      filteredData = { question, businesstype, venture, locationchoice, budget }
    }

    console.log('Filtered dataa:', filteredData)

    next(selection)
  }

  return (
    <div className='w-full h-full lg:h-full'>
      <h1 className='text-darkGreen font-semibold lg:text-lg'>
        Business Details
      </h1>
      <p className='ts text-[#666666] mt-1'>
        This details would help curate a personalized experience for you.
      </p>

      <form onSubmit={handleSubmit(onSubmit)} className='w-full space-y-4 mt-6'>
        <SelectInput
          control={control}
          Controller={Controller}
          data={question}
          title='Do you have an agri-business'
          name='question'
          placeholder='Select answer'
        />

        {/* Conditionally render components based on selectedAnswer */}
        {selectedAnswer === 'Yes' && (
          <div className='w-full'>
            <EnterpriseFarmRegistration
              setValue={setValue}
              errors={errors}
              isDirty={isDirty}
              isValid={isValid}
              register={register}
            />
          </div>
        )}
        {selectedAnswer === 'No' && (
          <div className='w-full space-y-6'>
            <SelectInput
              control={control}
              Controller={Controller}
              data={businessType}
              title='What type of agribusiness would you like to venture into?'
              name='businesstype'
              placeholder='Select answer'
            />

            <TextInput
              name='venture'
              register={register}
              errors={errors}
              text='What are you currently involved in?'
              placeholder='Input here'
            />
            <SelectInput
              control={control}
              Controller={Controller}
              data={location}
              title='What is your choice of location?'
              name='locationchoice'
              placeholder='Select answer'
            />
            <TextInput
              name='budget'
              register={register}
              errors={errors}
              text='What is your financial budget?'
              placeholder='Input here'
            />
            <button
              type='submit'
              className={`ts w-full ${
                isDirty && isValid ? 'activebutton' : 'notactive'
              }`}
              disabled={!(isDirty && isValid)}
            >
              Continue
            </button>
            <p className='login-text'>
              Have an account?{' '}
              <a href='/login' className='login-link'>
                Log in
              </a>
            </p>
          </div>
        )}
      </form>
    </div>
  )
}

export default EnterpriseBusinessDetails
