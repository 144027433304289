import React, { useEffect, useState } from 'react'
import { Steps } from 'antd'
import { ChevronLeft } from 'lucide-react'
import BackButton from '../SharedComponents/BackButton/BackButton'

const NavigationSteps = ({ First, Second, Third, onComplete, onAnswerSelect }) => {
  const [current, setCurrent] = useState(0)
  const [submitted, setSubmitted] = useState(false)
  const [formData, setFormData] = useState({})
  const [sucess, setSucess] = useState(false)
  const [stepSize, setStepSize] = useState('small')
  // Use media query to set size
  useEffect(() => {
    const handleResize = () => {
      setStepSize(window.innerWidth < 768 ? 'small' : 'default')
    }

    handleResize() // Set initial size
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  const next = () => {
    // Check if we're on the last step and should call onComplete
    if (current === steps.length - 1 && onComplete) {
      onComplete() // Trigger the onComplete callback
      setCurrent(0) // Reset back to the first step
    } else {
      setCurrent((prev) => prev + 1) // Move to the next step
    }
  }
  const prev = () => {
    setCurrent(current - 1)
  }

  const steps = [
    {
      content: <First next={next} setFormData={setFormData} onAnswerSelect={onAnswerSelect} />
    },
    Second
      ? {
          content: <Second next={next} onComplete={next} />
        }
      : null,
    Third
      ? {
          content: (
            <Third
              setSucess={setSucess}
              sucess={sucess}
              submitted={submitted}
              setSubmitted={setSubmitted}
              formData={formData}
            />
          )
        }
      : null
  ].filter(Boolean)

  // Map steps to items for the Steps component
  const items = steps.map((item, index) => ({
    key: index, // Unique key for each step
    title: `Step ${index + 1}` // Placeholder title; adjust as needed
  }))

  const contentStyle = {
    marginTop: 16
  }
  // Dynamic width class based on step count
  const stepsWidthClass = steps.length === 2 ? 'w-[50%]' : 'w-[80%]'
  return (
    <div className='w-full'>
      {submitted || sucess
        ? (
            ''
          )
        : (
          <div className='w-full flex justify-start items-center gap-4'>
            {current > 0
              ? (
                <div className=' lg:w-[25%]'>
                  <ChevronLeft
                    className='text-darkgreen cursor-pointer'
                    onClick={prev}
                  />
                </div>
                )
              : (
                <BackButton />
                )}
            <div className='flex justify-center  items-center w-full'>
              <Steps
                current={current}
                items={items}
                direction='horizontal'
                responsive={false}
                className={`${stepsWidthClass} flex mx-auto horizontal-steps `}
                size={stepSize}
              />
            </div>
          </div>
          )}
      <div style={contentStyle}>{steps[current].content}</div>
    </div>
  )
}

export default NavigationSteps
