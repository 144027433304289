import { MoreHorizontal } from 'lucide-react'
import { capitalizeWords } from '../../../../../config/services/capitalize'

export const columns = [
  {
    accessorKey: 'name',
    header: 'Name',
    cell: context => {
      const value = context.getValue()
      return <div className='w-[100px] ts'>{capitalizeWords(value)}</div>
    }
  },
  {
    accessorKey: 'state',
    header: () => <div className=''>State</div>,
    cell: context => {
      const value = context.getValue()
      return <div className='ts'>{capitalizeWords(value)}</div>
    }
  },
  {
    accessorKey: 'address',
    header: 'Address',
    cell: context => {
      const value = context.getValue()
      return <div className='ts'>{value}</div>
    }
  },
  {
    accessorKey: 'additional_data',
    header: 'Staffs',
    cell: context => {
      const additionalData = context.getValue()
      const staffCount = additionalData ? additionalData.staffs : 'N/A'
      return <span className='ts'>{staffCount}</span>
    }
  },
  {
    accessorKey: 'email',
    header: 'Email',
    cell: context => {
      const value = context.getValue()
      return <div className='ts'>{capitalizeWords(value)}</div>
    }
  },
  {
    accessorKey: 'action',
    header: 'Action',
    enableHiding: false,
    cell: () => {
      return <MoreHorizontal className='h-4 w-4' />
    }
  }
]
