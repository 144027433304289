import React from 'react'
import SelectInput from '../../SharedComponents/SelectInput/SelectInput'
import { Controller, useForm } from 'react-hook-form'
import TextInput from '../../SharedComponents/TextInput/TextInput'
import TextArea from '../../SharedComponents/TextAreaInput/TextAreaInput'

const EnterpriseExisitingLandForm = () => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors, isDirty, isValid }
  } = useForm()
  const question = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' }
  ]
  const resourcesquestion = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' }
  ]

  const selectedAnswer = watch('question')
  const onSubmit = async (data) => {
    let filteredData

    if (selectedAnswer === 'Yes') {
    // Submit only fields relevant to "Yes" selection
      const { question, landsize, agricresources, resourceslist, info } = data
      filteredData = { question, landsize, agricresources, resourceslist, info }
    } else if (selectedAnswer === 'No') {
    // Submit only fields relevant to "No" selection
      const { question, resourcesquestion, info } = data
      filteredData = { question, resourcesquestion, info }
    }

    console.log('Filtered data:', filteredData)
  }
  return (
    <div className='w-full h-[90vh] lg:h-full '>
      <h1 className='text-darkGreen font-semibold lg:text-lg'>
        Business Details
      </h1>
      <p className='ts text-[#666666]'>
        This details would help curate a personalized experience for you.
      </p>

      <form onSubmit={handleSubmit(onSubmit)} className='w-full space-y-4 mt-6'>
        <SelectInput
          control={control}
          Controller={Controller}
          data={question}
          title='Do you have existing land for agribusiness?'
          name='question'
          placeholder='Select answer'
        />

        {/* Conditionally render components based on selectedAnswer */}
        {selectedAnswer === 'Yes' && (
          <div className='w-full space-y-6'>
            <TextInput
              name='landsize'
              register={register}
              errors={errors}
              text='What is the size of the
              land?'
              placeholder='Input product'
            />
            <SelectInput
              control={control}
              Controller={Controller}
              data={resourcesquestion}
              title='Do you have any available agricultural resources or equipment?'
              name='agricresources'
              placeholder='Select answer'
            />
            <TextInput
              name='resourceslist'
              register={register}
              errors={errors}
              text='List your available resources or equipment'
              placeholder='Input product'
            />
            <div className='w-full'>
              <TextArea
                name='info'
                register={register}
                errors={errors}
                text='Any other additional information?'
                placeholder='Input here'
              />
            </div>
            <button
              type='submit'
              className={`ts w-full ${
              isDirty && isValid ? 'activebutton' : 'notactive'
            }`}
              disabled={!(isDirty && isValid)}
            >
              Submit
            </button>
            <p className='login-text'>
              Have an account?{' '}
              <a href='/login' className='login-link'>
                Log in
              </a>
            </p>
          </div>
        )}
        {selectedAnswer === 'No' && (
          <div className='w-full space-y-6'>
            <SelectInput
              control={control}
              Controller={Controller}
              data={resourcesquestion}
              title='Do you have any available agricultural resources or equipment?'
              name='resourcesquestion'
              placeholder='Select answer'
            />

            <div className='w-full'>
              <TextArea
                name='information'
                register={register}
                errors={errors}
                text='Any other additional information?'
                placeholder='Input here'
              />
            </div>
            <button
              type='submit'
              className={`ts w-full ${
              isDirty && isValid ? 'activebutton' : 'notactive'
            }`}
              disabled={!(isDirty && isValid)}
            >
              Submit
            </button>
            <p className='login-text'>
              Have an account?{' '}
              <a href='/login' className='login-link'>
                Log in
              </a>
            </p>
          </div>
        )}
      </form>
    </div>
  )
}

export default EnterpriseExisitingLandForm
