import React, { useEffect, useState } from 'react'
import InvestInformationContainer from '../InvestmentInformationContainer/InvestmentInformationContainer'
import useBackendService from '../../../../config/services/backend-service'

import { useParams } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import Modal from '../../SharedComponents/Modal/Modal'

function InvestInformation ({ unlock = false }) {
  const { id } = useParams()
  const [data, setData] = useState({})
  const [modal, setModal] = useState({})

  const { mutate: fetchInvestment } = useBackendService(
    `/investments/${id}`,
    'get',
    {
      onSuccess: response => {
        setData(response.data)
      },
      onError: error => {
        setModal({
          type: 'error',
          title: 'Unable to fetch catalogue data',
          desc: 'There was an issue retrieving the catalogue data. Please try again.'
        })
        document.getElementById('my_modal_5').showModal()
      }
    }
  )

  useEffect(() => {
    fetchInvestment()
  }, [fetchInvestment])
  return (
    <main className=' bg-white p-5 '>
      {/* <div className='w-full h-full p-[2%] bg-white'> */}
      {unlock && (
        <div className='mb-6'>
          <h1 className='text-3xl text-darkGreen font-bold'>Invest</h1>
          <p className='text-sm text-gray-600'>
            Check out investment plans for you.
          </p>
        </div>
      )}
      <InvestInformationContainer item={data} />
      {modal.type && (
        <Modal
          type={modal.type}
          title={modal.title}
          desc={modal.desc}
          link={modal.link}
        />
      )}
    </main>
  )
}

export default InvestInformation
