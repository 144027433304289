import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isAuthenticated: false,
  token: null,
  firstTime: null,
  hasAccount: null,
  isParentFarm: null,

  user: {
    id: null,
    firstName: '',
    lastName: '',
    isAdmin: false,
    location: '',
    plan: '',
    phoneNumber: '',
    role: '',
    directSupervisor: null,
    email: '',
    farmId: '',
    verified: false
  }
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true
      state.token = action.payload.token
      state.firstTime = action.payload.user.firstTimeLogin
      state.hasAccount = action.payload.user.hasAccount
      state.isParentFarm = action.payload.user.isParentFarm

      state.user = {
        id: action.payload.user.id,
        firstName: action.payload.user.first_name,
        lastName: action.payload.user.last_name,
        isAdmin: action.payload.user.is_admin,
        location: action.payload.user.location,
        plan: action.payload.user.plan,
        phoneNumber: action.payload.user.phone_number,
        role: action.payload.user.role,
        directSupervisor: action.payload.user.direct_supervisor,
        email: action.payload.user.email,
        farmId: action.payload.user.farm_id,
        verified: action.payload.user.verified
      }
    },
    logout: state => {
      state.isAuthenticated = false
      state.token = null
      state.firstTime = null
      state.user = {
        id: null,
        firstName: '',
        lastName: '',
        isAdmin: false,
        location: '',
        plan: '',
        phoneNumber: '',
        role: '',
        directSupervisor: null,
        email: '',
        farmId: '',
        verified: false,
        firstTime: null
      }
    },
    updateFirstTime: state => {
      state.firstTime = false
    }
  }
})

export const { login, logout, updateFirstTime } = authSlice.actions
export default authSlice.reducer
