import React, { useEffect, useState } from 'react'
import InvestContainer from '../InvestContainer/InvestContainer'
import useBackendService from '../../../../config/services/backend-service'

const RegularInvest = ({ unlock = false }) => {
  const [data, setData] = useState([])

  const { mutate: fetchInvest } = useBackendService(
    '/investments?status=approved',
    'get',
    {
      onSuccess: response => {
        setData(response.data)
      },
      onError: error => {
        console.error('Failed to fetch products:', error)
      }
    }
  )

  useEffect(() => {
    fetchInvest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='w-full h-full p-[2%] bg-white'>
      {unlock && (
        <div className='mb-6'>
          <h1 className='text-3xl text-darkGreen font-bold'>Invest</h1>
          <p className='text-sm text-gray-600'>
            Check out investment plans for you.
          </p>
        </div>
      )}
      <div className='grid grid-cols-2 lg:grid-cols-3 gap-2 lg:gap-10 p-5 '>
        {data.map(item => (
          <div className='lg:w-full w-[full]' key={item.id}>
            <InvestContainer item={item} unlock={unlock} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default RegularInvest
