import React, { useState } from 'react'
import TextInput from '../../SharedComponents/TextInput/TextInput'
import { CalendarDays } from 'lucide-react'
import { format, startOfToday } from 'date-fns'
import OperationalCalendarSelector from '../../EnterpriseApp/OperationalCalendar/OperationalCalendarSelector/OperationalCalendarSelector'
const EnterpriseFarmRegistration = ({ setValue, register, errors, isDirty, isValid }) => {
  // const { setparentFarmField } = useAuth()
  const today = startOfToday()
  const [selectedDay, setSelectedDay] = useState('')
  const formattedSelectedDay = selectedDay
    ? format(new Date(selectedDay), 'dd-MM-yyyy')
    : ''

  const handleSave = () => {
    if (selectedDay) {
      setValue('year', formattedSelectedDay)
      console.log('Selected Date:', selectedDay)
    }
  }
  // const onSubmit = async data => {
  //   setparentFarmField('farmname', data.farmname)
  //   setparentFarmField('location', data.location)
  //   setparentFarmField('year', data.year)
  //   setparentFarmField('staffs', data.staffs)
  //   setparentFarmField('branches', data.branch)

  // }
  return (

    <div className='w-full space-y-6'>

      <div className='grid w-full  items-center gap-1.5 ts'>
        <label htmlFor='text'>Business name</label>
        <input
          type='text'
          name='text'
          {...register('farmname', { required: 'Businessname is required' })}
          className='email-input input-style ts'
          placeholder='Branch'
        />
        {errors.farmname && (
          <p className='error-text'>{errors.farmname.message}</p>
        )}
      </div>

      <div
        className='w-full' onClick={() =>
          document.getElementById('calendar').showModal()}
      >
        <TextInput
          name='year'
          register={register}
          errors={errors}
          text='Year founded'
          dvalue={formattedSelectedDay}
          placeholder='Select year'
          calendaricon={
            <CalendarDays
              size={20}
            />
                }
        />
        {errors.year && (
          <p className='error-text'>{errors.year.message}</p>
        )}
      </div>
      <div className='grid w-full  items-center gap-1.5 ts'>
        <label htmlFor='text'>Location(s)</label>
        <input
          type='text'
          name='text'
          {...register('location', { required: 'Location is required' })}
          className='email-input input-style ts'
          placeholder='Taraba'
        />
        {errors.location && (
          <p className='error-text'>{errors.location.message}</p>
        )}
      </div>
      <div className='w-full center gap-3'>
        <div className='grid w-[50%]  items-center gap-1.5 ts'>
          <label htmlFor='text'>Number of staffs</label>
          <input
            type='number'
            name='text'
            {...register('staffs', { required: 'Staff is required' })}
            className='email-input input-style ts'
            placeholder='0'
          />
          {errors.staffs && (
            <p className='error-text'>{errors.staffs.message}</p>
          )}
        </div>

        <div className='grid w-[50%]  items-center gap-1.5 ts'>
          <label htmlFor='text'>Number of branch</label>
          <input
            type='number'
            name='text'
            {...register('branch', { required: 'Branch is required' })}
            className='email-input input-style ts'
            placeholder='0'
          />
          {errors.branch && (
            <p className='error-text'>{errors.branch.message}</p>
          )}
        </div>
      </div>

      <button
        type='submit'
        className={`ts w-full ${
                isDirty && isValid ? 'activebutton' : 'notactive'
              }`}
        disabled={!(isDirty && isValid)}
      >
        Continue
      </button>
      <OperationalCalendarSelector
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
        today={today}
        onSave={handleSave}
        nofuture='true'
      />

      <p className='login-text'>
        Have an account?{' '}
        <a href='/login' className='login-link'>
          Log in
        </a>
      </p>
    </div>

  )
}

export default EnterpriseFarmRegistration
