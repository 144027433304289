import { Plus } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import DashBoardHeader from '../DashBoard/DashBoardHeader/DashBoardHeader'
import StockDataTable from '../../StockPage/StockDataTable/StockDataTable'
import useBackendService from '../../../../config/services/backend-service'
import { useAuth } from '../../../../config/contexts/authProvider'
import Modal from '../../SharedComponents/Modal/Modal'
import Hurry from '../../../resources/hurry.png'
import Lock from '../../../resources/greenlock.png'
const StockPage = () => {
  const { firstTime, user } = useAuth()
  const [isModalOpen, setModalOpen] = useState(false)
  const [isModalOpened, setModalOpened] = useState(false)
  const showModal = () => {
    setModalOpen(true)
  }

  useEffect(() => {
    if (firstTime) {
      showModal()
    }
  }, [firstTime])

  const [data, setData] = useState([])

  const { mutate } = useBackendService(
    `/stock_records/parent/${user.farmId}`,
    'get',
    {
      onSuccess: data => {
        setData(data.data)
      },
      onError: error => {
        console.log(error)
      }
    }
  )
  useEffect(() => {
    if (!user.farmId) {
      setModalOpened(true)
    } else {
      mutate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])
  return (
    <div className='farmwrapper'>
      <DashBoardHeader
        title='Stock'
        btntext='Add new stock report'
        link='/stocks/new-stock'
        icon={<Plus size={20} />}
        parent={true}
      />
      <StockDataTable data={data} />

      <Modal
        title='Welcome to T-Gaims'
        desc='Create your first calendar now and get personalized guidance tailored to your success in your field.'
        welcomeicon={Hurry}
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
      />
      {/* modal for no business */}
      <Modal
        title='Create a business to unlock management tools'
        desc='Set up your agri-business to access all the tools you need to manage, track and grow with ease.'
        welcomeicon={Lock}
        isOpen={isModalOpened}
        linkOverride='true'
        onClose={() => setModalOpened(false)}
      />
    </div>
  )
}

export default StockPage
