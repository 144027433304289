import React, { useEffect, useState } from 'react'
import { ArrowLeft, CircleAlert } from 'lucide-react'
import SalesSection from '../../Home/SalesSection/SalesSection'
import { useAuth } from '../../../../../config/contexts/authProvider'
import Modal from '../Modal/Modal'
import './CatalogDescription.css'
import { Link, useParams } from 'react-router-dom'
import useBackendService from '../../../../../config/services/backend-service'
import 'react-toastify/dist/ReactToastify.css'

const CatalogDescription = ({ unlock = false }) => {
  const { user } = useAuth()
  const { id } = useParams()
  const [data, setData] = useState({})
  const [selectedImg, setSelectedImg] = useState(0)

  const { mutate: fetchCatalogue } = useBackendService(
    `/product_catalogues/${id}`,
    'get',
    {
      onSuccess: response => {
        setData(response.data)
      },
      onError: error => {}
    }
  )

  useEffect(() => {
    fetchCatalogue()
  }, [fetchCatalogue])

  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <div className='w-full h-full p-[2%]'>
      {unlock && (
        <div className='mb-6'>
          <h1 className='text-3xl text-darkGreen font-bold'>Catalog</h1>
          <p className='text-sm text-gray-600'>
            See all agribusiness related products and how best to manage them.
          </p>
        </div>
      )}
      <div className='w-[5%]'>
        {unlock ? (
          <Link to='/market/catalog'>
            <ArrowLeft />
          </Link>
        ) : (
          <Link to='/catalog'>
            <ArrowLeft />
          </Link>
        )}
      </div>
      <h1 className='mt-2 font-semibold text-darkGreen'>{data.name}</h1>
      <div className='w-full center gap-2 flex-col lg:flex-row mt-2 '>
        <div className='lg:w-[50%] w-full h-screen'>
          <div className='w-full h-[60vh] center gap-2 flex-col '>
            {/* big image gallery */}
            <div className='w-full h-[40vh] bg-black rounded-md'>
              {data.images?.length > 0 && (
                <img
                  src={data.images[selectedImg].image_url}
                  alt='product'
                  className='rounded-lg w-full h-full object-cover'
                />
              )}
            </div>
            <div className='w-full h-[20vh] flex gap-2'>
              {data.images?.map((img, index) => (
                <div key={index} className='w-[25%] h-full rounded-md'>
                  <img
                    src={img.image_url}
                    alt='product'
                    className='smallcard'
                    onClick={() => setSelectedImg(index)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className='w-full h-[40vh] pt-4'>
            <h1 className='text-darkGreen font-semibold'>Description:</h1>
            <p className='text-black ts'>{data.description}</p>
            <div className='w-full ts space-y-1 mt-4'>
              <h1 className='text-darkGreen font-semibold'>
                Nutritional/Market value:
              </h1>
              {data.nutrition_market_value?.map((item, index) => (
                <React.Fragment key={index}>
                  <p>
                    <span className='font-semibold capitalize'>
                      {item.title}:
                    </span>
                    {item.value}
                  </p>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
        <div className='lg:w-[50%] w-full h-screen'>
          <div className='w-full h-[40vh]'>
            <h1 className='text-darkGreen font-semibold'>Growth tips:</h1>
            <ul className='ts'>
              {data.growing_tips?.map((tip, index) => (
                <li key={index}>{tip.value}</li>
              ))}
            </ul>
          </div>
          <div
            className='w-full  bg-darkGreen rounded-lg text-white ts'
            onClick={openModal}
          >
            <h1 className='font-semibold p-[2%]'>Care instructions:</h1>
            <hr className='opacity-15' />
            {data.care_instructions?.map((item, index) => (
              <React.Fragment key={index}>
                <p className='p-[2%]'>{item.value}</p>
                <hr className='opacity-15' />
              </React.Fragment>
            ))}
          </div>
          <Modal show={isModalOpen} onClose={closeModal} product={data} />
        </div>
      </div>
      {unlock || user.plan === 'Pro' ? (
        <div className='w-full flex flex-col justify-center items-start'>
          <h1 className='text-darkGreen font-semibold'>
            Sales & Supply Chain Information🎉
          </h1>
          <p className='text-[#515151] ts'>
            Stay ahead of the market with our sales & supply chain information.
          </p>
          <div className='w-full flex flex-wrap justify-start gap-4 items-center mt-6 '>
            {data.market_prices?.map((item, index) => (
              <div
                className='lg:w-[32%] w-full flex justify-center items-start gap-2'
                key={index}
              >
                <div className='w-[10%] center'>
                  <CircleAlert className='text-darkGreen' />
                </div>
                <div className='w-[90%] ts'>
                  <h1 className='text-darkGreen font-semibold'>{item.date}</h1>
                  <p className='text-[#515151]'>{item.value}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <SalesSection
          title='Sales & Supply'
          titletwo='Chain Information🎉'
          desc='Stay ahead of the market with our sales & supply chain information.'
          me='px-0'
        />
      )}
    </div>
  )
}

export default CatalogDescription
