import React from 'react'
import Header from '../../components/Header/Header'
import { useForm } from 'react-hook-form'
import TextInput from '../../../farmapp/components/SharedComponents/TextInput/TextInput'
import TextArea from '../../../farmapp/components/SharedComponents/TextAreaInput/TextAreaInput'
import Footer from '../../components/Footer/Footer'
import { toast } from 'react-toastify'
import useBackendService from '../../../config/services/backend-service'
import { useNavigate } from 'react-router-dom'

const GetInTouch = () => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid }
  } = useForm()

  const { mutate: getInTouch, isLoading } = useBackendService(
    '/auth/send_message',
    'post',
    {
      onSuccess: data => {
        toast.success('Message sent successfully!')
        navigate('/')
      },
      onError: err => {
        toast.error('Error submitting the form')
      }
    }
  )

  const onSubmit = async data => {
    if (!data.name || !data.email || !data.subject || !data.message) {
      toast.error('All fields are required!')
      return
    }

    getInTouch(data)
  }

  return (
    <div className='flex flex-col gap-[20px] bg-white'>
      <Header />
      <section className='w-full'>
        <div className='text-center flex flex-col gap-[10px] p-5 w-full lg:w-[60%] mx-auto'>
          <p className='text-[25px] lg:text-[33px]  text-black font-[600] leading-[26px] lg:leading-[60px]'>
            Connect with Our Team
          </p>
          <p className='text-grey font-[500] text-[13px] w-full lg:w-[75%] mx-auto lg:text-[16px] leading-[19.5px] lg:leading-[24px]'>
            Whether you have questions, need assistance, or are ready to start a
            new project, our team is here to help. Connect with us today to
            discover how we can support your goals and bring your ideas to life.
            We’re just a message away!
          </p>
        </div>
        <div className='flex w-full flex-col lg:flex-row items-start gap-4 justify-center p-[4%]'>
          {/* left */}
          <div className='w-full lg:w-[40%] bg-darkGreen rounded-lg h-[550px] ts p-[4%] lg:p-[2%] text-white relative'>
            <img
              src={require('../../assets/circlegetintouch.png')}
              className='absolute right-0 bottom-0'
              alt='circle'
            />
            <h1 className='text-[16px] lg:text-[22px] font-[500]'>
              Contact Details
            </h1>
            <p className='text-[13px] lg:text-[15px] font-[400] text-[#dddddd] mt-2 leading-[22px]'>
              We offer a range of options designed to help you manage your farm
              efficiently.
            </p>
            <div className='w-full flex flex-col mt-7 space-y-10'>
              <div className='flex w-full gap-5 lg:h-[50px] h-[45px]'>
                <img
                  src={require('../../assets/locate.png')}
                  className='lg:w-[50px] lg:h-[50px] h-[45px] w-[45px]'
                  alt='circle'
                />
                <div className='flex flex-col gap-3'>
                  <h1 className='text-[13px] lg:text-[16px] font-[500] text-white'>
                    Address
                  </h1>
                  <p className='text-[12px] lg:text-[14px] text-[#DDDDDD] font-[400]'>
                    Unit 2 Wetland estate phase 3, Aguwan Anaekwe street, Wuye
                    Abuja Nigeria
                  </p>
                </div>
              </div>
              <div className='flex w-full gap-5 lg:h-[50px] h-[45px]'>
                <img
                  src={require('../../assets/phone.png')}
                  className='lg:w-[50px] lg:h-[50px] h-[45px] w-[45px]'
                  alt='circle'
                />
                <div className='flex flex-col gap-3'>
                  <h1 className='text-[13px] lg:text-[16px] font-[500] text-white'>
                    Phone
                  </h1>
                  <p className='text-[12px] lg:text-[14px] text-[#DDDDDD] font-[400]'>
                    +234 812 3456 789
                  </p>
                </div>
              </div>
              <div className='flex w-full gap-5 lg:h-[50px] h-[45px]'>
                <img
                  src={require('../../assets/mailbox.png')}
                  className='lg:w-[50px] lg:h-[50px] h-[45px] w-[45px]'
                  alt='circle'
                />
                <div className='flex flex-col gap-3'>
                  <h1 className='text-[13px] lg:text-[16px] font-[500] text-white'>
                    Email
                  </h1>
                  <p className='text-[12px] lg:text-[14px] text-[#DDDDDD] font-[400]'>
                    support@tgaims.com
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* right */}
          <div className='w-full lg:w-[60%] p-[2%]'>
            <h1 className='text-[16px] lg:text-[18px] font-[500]'>
              Send us a Message
            </h1>
            <form
              className='w-full mt-4 space-y-5'
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className='w-full flex justify-between items-start gap-6'>
                <div className='w-full lg:w-[50%]'>
                  <TextInput
                    register={register}
                    name='name'
                    text='Your Name'
                    errors={errors}
                    placeholder='Input Here'
                  />
                </div>
                <div className='w-full lg:w-[50%]'>
                  <TextInput
                    register={register}
                    name='email'
                    text='Your Email'
                    errors={errors}
                    placeholder='Input Here'
                  />
                </div>
              </div>
              <div className='w-full'>
                <TextInput
                  register={register}
                  name='subject'
                  text='Subject'
                  errors={errors}
                  placeholder='Input Here'
                />
              </div>
              <div className='w-full'>
                <TextArea
                  name='message'
                  register={register}
                  errors={errors}
                  text='Message'
                  placeholder='Input your message here'
                />
              </div>
              <div className='w-full flex justify-start items-start'>
                <button
                  type='submit'
                  disabled={isLoading || !(isDirty && isValid)} // Disable button if loading or if form is invalid
                  className={`w-full lg:w-[50%] lg:h-[50px] ts ${
                    isDirty && isValid && !isLoading
                      ? 'proceed-btn bg-darkGreen'
                      : 'rounded-[8px] bg-darkash text-black'
                  } flex items-center justify-center`}
                >
                  {isLoading ? (
                    <div className='spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full' /> // Add a spinner here
                  ) : (
                    'Send Message'
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
      <div className='w-full relative flex items-center justify-center'>
        <h1 className='absolute font-[600] text-base text-[18px] leading-[30px] lg:text-[36px] lg:leading-[63px] text-center px-[5%] text-white'>
          We help you take charge and run your Agricultural processes more
          efficiently all from one platform
        </h1>
        <img
          src={require('../../assets/plant.png')}
          className='w-full h-[200px] lg:h-[400px] object-cover'
          alt=''
        />
      </div>
      <Footer />
    </div>
  )
}

export default GetInTouch
