import { capitalizeWords } from '../../../../../config/services/capitalize'

export const columns = [
  {
    accessorKey: 'name',
    header: 'Name',
    cell: ({ row }) => `${row.original.first_name} ${row.original.last_name}`
  },
  {
    accessorKey: 'email',
    header: 'Email',
    cell: context => (
      <span className='ts'>{capitalizeWords(context.getValue())}</span>
    )
  },
  {
    accessorKey: 'phone_number',
    header: 'Number',
    cell: context => <span className='ts'>{context.getValue()}</span>
  },
  {
    accessorKey: 'role',
    header: 'Role',
    cell: context => (
      <span className='ts'>{capitalizeWords(context.getValue())}</span>
    )
  },
  {
    accessorKey: 'location',
    header: 'Location',
    cell: context => (
      <span className='ts'>{capitalizeWords(context.getValue())}</span>
    )
  }
]
