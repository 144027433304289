const BasicPlanProductContent = [
  {
    id: 1,
    img: require('./assets/1.png'),
    title: 'Crop and livestock catalog',
    text: 'Access to our extensive catalog of crops and livestock that are ideal for cultivation in Nigeria.'
  },
  {
    id: 2,
    img: require('./assets/2_1.png'),
    title: 'Daily agribusiness growth information',
    text: "Access to information about each crop and livestock's growing requirements."
  },
  {
    id: 3,
    img: require('./assets/3_1.png'),
    title: 'Relevant agri news',
    text: 'Stay updated with the latest trends, breakthroughs, and regulatory changes in the agricultural sector.'
  },
  {
    id: 4,
    img: require('./assets/4_1.png'),
    title: 'Comprehensive agribusiness investment management',
    text: 'Manage your investments with detailed insights and tools to maximize returns and minimize risks.'
  },
  {
    id: 5,
    img: require('./assets/5.png'),
    title: 'Relevant daily weather forecast',
    text: 'Get precise weather updates to make informed decisions about irrigation, planting, and harvesting.'
  }
]

const BasicPlanPricingContent = [
  {
    id: 1,
    content: 'Crop and livestock catalog'
  },
  {
    id: 2,
    content: 'Daily agribusiness growth information'
  },
  {
    id: 3,
    content: 'Relevant agri news'
  },
  {
    id: 4,
    content: 'Relevant daily weather forecast'
  },
  {
    id: 5,
    content: 'Comprehensive agribusiness investment management'
  }
]
const ProPlanPricingContent = [
  {
    id: 1,
    content: 'Basic features included.'
  },
  {
    id: 2,
    content: 'Expertly curated crop & livestock map.'
  },
  {
    id: 3,
    content: 'Potential yield data'
  },
  {
    id: 4,
    content: 'Crop and livestock harvest details'
  },
  {
    id: 5,
    content: 'Comprehensive catalog of agricultural products.'
  },
  {
    id: 6,
    content: 'Supply chain and sales information.'
  }
]
const EnterprisePricingContent = [
  {
    id: 1,
    content: 'Pro features included.'
  },
  {
    id: 2,
    content: 'Stock reporting interface'
  },
  {
    id: 3,
    content: 'Stock values dashboard'
  },
  {
    id: 4,
    content: 'Staff request/report interface'
  },
  {
    id: 5,
    content: 'Supply chain tracking'
  },
  {
    id: 6,
    content: 'T-Gaims low level inventory alert'
  },
  {
    id: 7,
    content: 'Bespoke agribusiness consultation'
  },
  {
    id: 8,
    content: 'Organizational calendar'
  }
]

const ProPlanProductContent = [
  {
    id: 1,
    img: require('./assets/1.png'),
    title: 'Everything in Basic plan.',
    text: 'This plan includes all the features of the basic plan, plus the following features'
  },

  {
    id: 2,
    img: require('./assets/2_1.png'),
    title: 'Expertly curated crop & livestock map.',
    text: 'Access to an expertly curated map view displaying optimal growing regions for various crops based on climate, soil type, and local conditions to maximize your agricultural success.'
  },
  {
    id: 3,
    img: require('./assets/3_1.png'),
    title: 'Potential yield data',
    text: 'Access to information about each crop’s and livestock potential yield.'
  },
  {
    id: 4,
    img: require('./assets/4_1.png'),
    title: 'Crop and livestock harvest details',
    text: 'Access schedules and best practices to ensure optimal quality and yield during harvest.'
  },
  {
    id: 5,
    img: require('./assets/5.png'),
    title: 'Comprehensive catalog of agricultural products.',
    text: 'Access to data our extensive catalog of feeds, pesticides, and fertilizers, featuring expert recommendations on where to get the best quality and prices to ensure maximum efficiency and productivity with every purchase.'
  },
  {
    id: 6,
    img: require('./assets/6.png'),
    title: 'Supply chain and sales information.',
    text: 'Access to supply chain information (i.e how to transport harvest, who to sell to e.t.c)'
  }
]

const EnterpriseProductsContent = [
  {
    id: 1,
    img: require('./assets/1.png'),
    title: 'Everything in Pro plan',
    text: 'This plan includes all the features of the Pro plan, plus the following features'
  },
  {
    id: 2,
    img: require('./assets/2_1.png'),
    title: 'Stock reporting interface',
    text: 'A stock reporting interface for reporting stock values.'
  },
  {
    id: 3,
    img: require('./assets/3_1.png'),
    title: 'Stock values dashboard',
    text: 'A stock dashboard detailing the stock values across every market.'
  },
  {
    id: 4,
    img: require('./assets/4_1.png'),
    title: 'Staff request/report interface',
    text: 'A staff request and report interface to streamline communication between businesses and HQ.'
  },
  {
    id: 5,
    img: require('./assets/5.png'),
    title: 'Supply chain tracking',
    text: 'An extensive supply chain interface that tracks shipments to and from the businesses.'
  },
  {
    id: 6,
    img: require('./assets/6.png'),
    title: 'T-gaims Low inventory alert system',
    text: 'An alert system that sends alert to relevant staff when certain products are low in inventory.'
  },
  {
    id: 7,
    img: require('./assets/7.png'),
    title: 'Bespoke agribusiness consultation',
    text: 'Receive personalized advice and strategies to enhance the efficiency and profitability of your business.'
  },
  {
    id: 8,
    img: require('./assets/6.png'),
    title: 'Organizational calendar',
    text: 'On signup you can create a step-by-step, guide for the organizations operations and product development, detailing key actions and timelines to ensure streamlined processes and efficient execution.'
  }
]

export {
  BasicPlanProductContent,
  ProPlanProductContent,
  EnterpriseProductsContent,
  BasicPlanPricingContent,
  ProPlanPricingContent,
  EnterprisePricingContent
}
