import React, { useState } from 'react'
import { Bell, Menu } from 'lucide-react'
import NotificationDrawer from '../../SharedComponents/NotificationDrawer/NotificationDrawer'

const TopNav = ({ onClick, location, menus }) => {
  const { pathname } = location

  const getNavData = () => {
    for (const menu of menus) {
      if (pathname.startsWith(menu.link)) {
        return menu
      }

      if (menu.submenu) {
        for (const subMenu of menu.submenu) {
          if (pathname.startsWith(subMenu.link)) {
            console.log('Matched submenu:', subMenu.name)
            return subMenu
          }
        }
      }
    }

    return null
  }

  const navdata = getNavData()
  const { link } = navdata || {}
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  return (
    <div className='topnav-container'>
      {link && (
        <div className='flex flex-col flex-5 lg:hidden gap-0 '>
          <p className='text-black font-[600] text-[18px]'>Hello, welcome👋</p>
          <p className='text-[#444] font-[400] text-[12px]'>
            Manage your agribusiness efficiently
          </p>
        </div>
      )}
      <div className='topnav-content-style'>
        <div className='center hidden lg:flex gap-2 text-gray-500'>
          <div className='bell-style' onClick={toggleDrawer}>
            <Bell size={20} />
          </div>
        </div>
        {link && (
          <div className='flex lg:hidden justify-end md:bg-primary md:p-3 md:rounded-full' onClick={onClick}>
            <Menu size={24} />
          </div>
        )}
        <NotificationDrawer
          isDrawerOpen={isDrawerOpen}
          toggleDrawer={toggleDrawer}
        />
      </div>
    </div>
  )
}

export default TopNav
