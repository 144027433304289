import React, { useEffect, useState } from 'react'
import TextInput from '../../../SharedComponents/TextInput/TextInput'
import TextArea from '../../../SharedComponents/TextAreaInput/TextAreaInput'
import SelectInput from '../../../SharedComponents/SelectInput/SelectInput'
import { Controller, useForm } from 'react-hook-form'
import BackButton from '../../../SharedComponents/BackButton/BackButton'
import useBackendService from '../../../../../config/services/backend-service'
import { useAuth } from '../../../../../config/contexts/authProvider'
import Modal from '../../../SharedComponents/Modal/Modal'

function AddRequest () {
  const [assignees, setAssignees] = useState([])
  const [isModalOpen, setModalOpen] = useState(false)
  const [modal, setModal] = useState({
    type: '',
    title: '',
    desc: '',
    link: '',
    visible: false // Add a visible property for modal
  })

  const { user } = useAuth()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid }
  } = useForm()

  const { mutate: fetchUsersBy } = useBackendService(
    `/farms/list_related_users/${user.farmId}`,
    'get',
    {
      onSuccess: response => {
        setAssignees(response.data)
      },
      onError: error => {
        console.log('Error fetching users by parent farm:', error)
      }
    }
  )

  useEffect(() => {
    fetchUsersBy()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { mutate: submitRequest } = useBackendService('/operations', 'post', {
    onSuccess: () => {
      setModal({
        type: 'success',
        title: 'Request added successfully',
        desc: 'Well done you have successfully added a new request.',
        link: '/operations',
        visible: true
      })
      setModalOpen(true)
    },
    onError: () => {
      setModal({
        title: 'Unable to add a request',
        desc: 'You are unable to add a request at the moment.',
        type: 'error',
        visible: true
      })
      setModalOpen(true)
    }
  })

  const onSubmit = data => {
    const transformedData = {
      ...data,
      title: data.subject,
      status: 'Pending',
      createdBy: user.id
    }
    submitRequest(transformedData)
  }

  const requestType = [
    { value: 'Administrative Request', label: 'Administrative Request' },
    { value: 'Financial Request', label: 'Financial Request' },
    { value: 'Human Resources Request', label: 'Human Resources Request' },
    { value: 'Operational Request', label: 'Operational Request' }
  ]

  return (
    <section className='flex flex-col bg-white lg:p-5 p-3 gap-4 lg:gap-8'>
      <BackButton size={24} />
      <div className='flex flex-col gap-2'>
        <p className='text-black text-[16px] lg:text-[24px] font-[600]'>
          Make a request
        </p>
        <p className='text-[12px] lg:text-[14px] text-grey'>
          Fill in the details for a new activity
        </p>
      </div>
      <form
        className='flex w-full flex-col gap-4 md:gap-8'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='flex flex-col md:flex-row gap-5 md:gap-10 items-center '>
          <div className='w-full md:w-[50%] '>
            <SelectInput
              control={control}
              Controller={Controller}
              data={requestType}
              title='Type'
              name='type'
              placeholder='Select the type of request'
            />
          </div>
          <div className='w-full md:w-[50%] '>
            <TextInput
              name='subject'
              register={register}
              errors={errors}
              text='Subject'
            />
          </div>
        </div>
        <div className='w-full '>
          <TextArea
            name='description'
            register={register}
            errors={errors}
            text='Description'
            placeholder='Type request description'
          />
        </div>
        <div className='w-full md:w-[50%] '>
          <SelectInput
            control={control}
            Controller={Controller}
            data={assignees}
            title='Assigned To'
            name='assignedTo'
            placeholder='Select who to send request to'
          />
        </div>
        <button
          type='submit'
          className={`w-full md:w-[50%] ts h-[50px] ${
            isValid
              ? 'proceed-btn bg-darkGreen'
              : 'rounded-[8px] bg-darkash text-black'
          }`}
          disabled={!isValid}
        >
          Submit Request
        </button>
      </form>
      {modal.visible && (
        <Modal
          type={modal.type}
          title={modal.title}
          desc={modal.desc}
          link={modal.link}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </section>
  )
}

export default AddRequest
